import { ContentBlocks } from "eddev/blocks"
import { defineView } from "eddev/views"

export default defineView("front-page", (props) => {
  return (
    <div>
      <ContentBlocks blocks={props.page?.contentBlocks} />
    </div>
  )
})
